/*========================
  COLORS
========================*/
$black: #000000;
$white: #FFFFFF;
$blue-10: #EDF6FA;
$blue-100: #C5E0FC;
$blue-500: #359EEA;
$blue-600: #4C8DEC;
$blue-700: #2C7EDF;
$blue-800: #3C67C5;
$blue-900: #2450AA;
$blue-1000: #00537E;
$red: #E93052;
$red-10: #FFE8E8;
$green: #24C581;
$gray-100: #E7E7E7;
$gray-400: #C2C2C2;
$gray-700: #686868;
$gray-900: #232323;
$gray-500: #A8A8A8;
$blue-gray-10: #F7F9FA;
$blue-gray-50: #E4ECF3;
$blue-gray-75: #DEE0E5;
$blue-gray-100: #C4D0D7;
$blue-gray-200: #A1AFBB;
$blue-gray-300: #7D8F9E;
$blue-gray-500: #4A6376;
$blue-gray-600: #3F5666;
$blue-gray-700: #304352;
$blue-gray-800: #22313D;
$blue-gray-900: #111E27;
$yellow-10: #FFFEF7;
$yellow-500: #FCFF7D;
$yellow-800: #E4A43E;

@import 'colors';

/*========================
  ELEMENT
========================*/
body {
  min-width: 1120px;
  font-size: 16px;
  color: $gray-900;
}

/*========================
  VISIBLITY
========================*/
.d-pc {
  display: block;
}

.d-sp {
  display: none;
}

.d-ib-pc {
  display: inline-block;
}

.d-ib-sp {
  display: none;
}

/*========================
  BACKGROUND
========================*/
.bg-dot {
  background-image:
    radial-gradient($gray-100 8%, transparent 10%);
  background-size: 14px 14px;
}

.pc-bg-light-blue {
  background: $blue-10;
}

/*========================
  CONTAINER
========================*/
/* ------- 横幅指定用コンテナ ------- */
.container-small {
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.container-middle {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.container-large {
  width: 1120px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

/* ------- 各ページ用コンテナ ------- */
.container-session,
.container-pages,
.container-project,
.container-apply {
  padding-top: 56px;
  padding-bottom: 56px;
  margin-left: auto;
  margin-right: auto;
}

.container-mypage {
  width: 1000px;
  padding-top: 56px;
  padding-bottom: 56px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.container-mypage-menu,
.container-search-sidebar {
  width: 300px;
  min-width: 300px;
  margin-right: 56px;
}

.container-mypage-content,
.container-search-content {
  width: 100%;
}

.container-mypage-noapply {
  padding: 56px 24px;
  border-radius: 4px;
  border: 1px solid $blue-gray-100;
}

.container-apply-login-form {
  width: 600px;
  padding-top: 24px;
  margin: 0 auto;
}

.container-search {
  width: 1120px;
  padding-top: 56px;
  padding-bottom: 56px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
}

.btn-wrap {
  width: 270px;
  margin: 0 auto;
}

/*========================
  HEADINGS
========================*/
/* ------- H2 ------- */
h2.session-title-layout,
h2.mypage-title-layout,
h2.pages-title-layout,
h2.http-status-error-title-layout {
  margin-bottom: 32px;
}

h2.apply-title-layout {
  margin-bottom: 24px;
}

h2.search-title-layout {
  margin-bottom: 16px;
}

h2.session-title,
h2.mypage-title,
h2.pages-title,
h2.apply-title,
h2.search-title,
h2.service-headline,
h2.http-status-error-title {
  font-size: 26px;
  font-weight: 700;
  letter-spacing: 0.05em;
}

/* ------- H3 ------- */
h3.mypage-subtitle {
  font-weight: 500;
  font-size: 20px;
  color: $blue-gray-500;
}

h3.article-number {
  font-weight: 700;
  font-size: 18px;
  color: $blue-gray-500;
}

h3.h3-ttl {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
}

/*========================
  COMPONENTS
========================*/
/* ------- CARD ------- */
.card-mypage-menu {
  width: 300px;
  padding: 24px;
  border-radius: 16px;
}

.card-white {
  border: 2px solid $blue-gray-100;
  background-color: $white;
}

.card-thanks-title {
  display: inline-block;
  border-radius: 100vh;
  background-color: $blue-700;  
  padding: 8px 24px;
  border: none;
}

.card-thanks-title-text {
  font-size: 18px;
  color: $white;
  text-decoration: none;
}
.card-not-found-project {
  border: 1px solid #C4D0D7;
  text-align: center;
  height: 164px;
  line-height: 26px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

/* ------- 案件カード ------- */
.card-project {
  margin-bottom: 32px;
  padding: 32px;
  border-radius: 16px;

  .apply-time-box {
    margin-bottom: 24px;
    padding: 16px 24px;
    font-size: 16px;
    line-height: 23px;
  }

  h3.project-card-ttl {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .project-card-occupation {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 22px;
    color: $gray-700;
  }

  ul.project-card-condition-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 24px;
    padding: 16px 24px;
    border-radius: 8px;
    background-color: $blue-gray-10;

    > li {
      white-space: nowrap;
      padding: 0 8px;
    }

    > li:last-child {
      overflow: hidden;
    }

    img.project-card-condition-icon {
      margin-right: 14px;
      width: 20px;
      height: 20px;
    }

    .income-num {
      font-size: 22px;
      line-height: 32px;
    }
    
    .income-unit {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .project-card-skill {
    margin-bottom: 16px;
  }

  ul.project-card-detail-list {
    border-top: 1px solid $blue-gray-50;
    margin-bottom: 24px;

    li.project-card-detail-content {
      display: flex;
      align-items: flex-start;
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid $blue-gray-50;

      ul.system-layer-list { 
        > li {
          display: flex;
        }

        > li:not(:first-child) {
          margin-top: 12px;
        }

        .system-layer-name {
          min-width: 170px;
          padding-right: 16px;
        }
      }

      .system-layer-list:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    .project-card-detail-title {
      width: 150px;
      min-width: 8em;
      margin-right: 40px;
    }
  }

  .project-card-btn-wrap {
    margin: 0 auto 8px;
  }

  .num-of-days {
    margin: 0;
    color: $gray-500;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
  }
}

/* ------- Applies ------- */
.apply-thanks-base-info {
  width: fit-content;
  margin-bottom: 40px;
  border: none;

  .img {
    width: 120px;
    height: 144px;
  }

  .text-caution {
    color: #FF696E;
    font-size: 20px;
  }

  .text-container {
    flex: 1;
    margin-left: 16px;
  }
}

/* ------- MYPAGE MENU ------- */
ul.mypage-menu {
  > li {
    border-bottom: 1px solid $blue-gray-50;

    a {
      display: flex;
      align-items: center;
      padding: 16px 8px;
      color: $gray-900;
      text-decoration: none;
    }

    .mypage-menu-icon {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .mypage-menu-arrow {
      display: inline-block;
      margin-left: auto;
      width: 16px;
      height: 16px;
    }
  }

  > li:last-child {
    border-bottom: none;
  }
}

/* ------- FORM ------- */
.form-select.year {
  width: 280px;
  margin-right: 4px;
  margin-bottom: 12px;
}

.form-select.month {
  width: 112px;
  margin: 0px 4px;
}

.form-select.day {
  width: 112px;
  margin: 0px 4px;
}

/* ------- 会社概要 ------- */
table.company {
  th {
    border: 1px solid $blue-gray-100;
    width: 25%;
    font-weight: 700;
    color: $blue-gray-500;
    background: $blue-gray-50;
    padding: 24px;
  }

  td {
    border: 1px solid $blue-gray-100;
    width: 75%;
    color: $gray-900;
    background: $white;
    padding: 24px;
  }
}

/* ------- その他 ------- */
.pc-step-nums {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.img-achievement {
  width: 462px;
  padding: 56px 0 120px;
  margin: 0 auto;
}

/*========================
  FONT (PC ONLY)
========================*/
/* ------- FONT SIZE ------- */
.pc-fs-16 {
  font-size: 16px;
}

.pc-fs-18 {
  font-size: 18px;
}

.pc-fs-20 {
  font-size: 20px;
}

.pc-fs-30 {
  font-size: 30px;
}

/* ------- FONT TYPE ------- */
.apply-login-title {
  font-size: 20px;
  font-weight: 600;
}

.session-body {
  font-size: 18px;
  font-weight: 400px;
}

/*========================
  SPACING
========================*/
/* ------- MARGINS ------- */
.pc-mt-48 {
  margin-top: 48px;
}

.pc-mt-56 {
  margin-top: 56px;
}

.pc-mb-16 {
  margin-bottom: 16px;
}

.pc-mb-24 {
  margin-bottom: 24px;
}

/* ------- PADDINGS ------- */
.pc-pt-40 {
  padding-top: 40px;
}

.pc-pt-56 {
  padding-top: 56px;
}

.pc-pt-116 {
  padding-top: 116px;
}

.pc-pt-160 {
  padding-top: 160px;
}

.pc-py-64 {
  padding: 64px 0;
}

.pc-py-72 {
  padding: 72px 0;
}

.pc-py-80 {
  padding: 80px 0;
}


/*========================
  後で消したい
========================*/
.main-content {
  padding: 150px 0 56px;
}

/*========================
  BACKGROUND
========================*/
.guide {
  display: flex;
  flex-direction: row;
  align-items: center;

  .three-step {
    width: calc(100%/3);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    padding: 0 16px;
  }

  .four-step {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    text-align: center;
    padding: 16px 12px;
  }

  .img-num {
    margin: 0 auto;
  }

  .step-icon {
    display: block;
    width: 56px;
    height: 56px;
    margin: 16px auto;
  }

  .step-icon-thanks {
    display: block;
    width: 120px;
    height: 120px;
    margin: 16px auto 16px;
  }

  .step-title {
    font-size: 18px;
    font-weight: 700;
  }

  .step-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
  }

  .content-area,
  .sp-content-area-with-line {
    margin-top: 16px;
    text-align: left;
  } 
}

.btn-back-wrap {
  width: 240px;
  margin-left: auto;
  margin-right: auto;
}

/*========================
  NOTIFY
========================*/
.message-bar-top {
  position: absolute;
  top: 94px;
  z-index: 9999;
}

.message-bar-message {
  min-width: 960px;
  padding: auto;
  text-align: left;
}

/*========================
  CARD
========================*/
.card-normal {
  padding: 32px;
  border-radius: 16px;
}

.card-long {
  padding: 32px 24px;
  border-radius: 16px;
}

.card-sm {
  padding: 24px;
  border-radius: 16px;
}

.card-article {
  padding: 24px;
  border-radius: 12px;
}

/* ------- BUTTON ------- */
.btn-onboarding {
  padding: 24px 16px;
  margin: 0 12px;
  width: 270px;
  height: 120px;
  background: $white;
  border: 1px solid $blue-gray-100;
  border-radius: 4px;
  color: $blue-700;
  font-weight: 700;
  text-decoration: none;
}

.btn-narrow, .btn-narrow:hover {
  width: 320px;
}

/* ------- FOOTER ------- */
.foofer {
  width:100%;
}

.footer-search-area {
  width: 100%;
  border-bottom: 1px solid $blue-gray-300;
  padding: 0 32px;
}

ul.footer-search {
  max-width: 1120px;
  padding-top: 80px;
}

ul.footer-search,
ul.nested-footer-search {
  margin: 0 auto;

  p {
    margin: 0;
    padding: 16px 0;
  }

  > li.search-category {
    width: 100%;
  }

  > li.nested-search-category {
    width: 100%;
  }

  .search-category-text {
    color:$white;
    font-weight: 500;
  }

  .footer-search-index {
    display: flex;
    justify-content: flex-start;
  }

  .search-item-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }

  .nested-search-item-list {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
  }

  .footer-occupation-list {
    width: 25%;
  }
  
  .footer-system-object-list {
    width: calc(100%/6);
  }
  
  .footer-monthly-income-list {
    width: calc(100%/6);
  }
  
  .footer-location-list {
    width: calc(100%/7);
  }  

  .search-item:not(:last-child) {
    padding-bottom: 12px;
  }

  .search-item-text {
    font-size: 14px;
    letter-spacing: 0.05em;
    color: $white;
    text-decoration: none;
  }

  .footer-occupation-list {
    display: inline-block;
    min-width: 288px;
  }
  
  .footer-system-object-list,
  .footer-monthly-income-list,
  .footer-location-list {
    display: inline-block;
    min-width: 144px;
    padding-right: 24px;
  }
}

.footer-info-area {
  width: 100%;
  padding: 0 32px;
}

.footer-info {
  max-width: 1120px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.footer-info-menu {
  padding-top: 32px;
  padding-bottom: 32px;

 a {
    display: flex;
    justify-content: space-between;
  }

  p {
    margin: 0 8px 0 0;
  }

  .footer-info-text {
    color: $white;
    font-weight: 400;
    font-size: 16px;
    text-decoration: none;
  }
}

.footer-info-menu:not(:last-child) {
  margin-right: 60px;
}

.footer-bottom-area {
  width: 100%;
  padding: 0 32px;
}

.footer-bottom {
  max-width: 1120px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;

  .logo-area {
    min-width: 600px;
  }

  .logo-area a {
    display: block;
    width: fit-content;
    height: fit-content;
  }

  a.footer-logo > img {
    width: 200px;
  }

  .footer-logo-text {
    font-size: 12px;
    font-weight: 400;
    color: $white;
    letter-spacing: 0.05em;
    margin-bottom: 0;
  }

  .memu-area {
    width: 100%;
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  ul.footer-bottom-menu {
    display: flex;
  }

  ul.footer-bottom-menu > li a {
    display: block;
    padding: 0px 16px;
    text-align: center;
  }

  ul.footer-bottom-menu > li:not(:last-child) {
    border-right: 1px solid $blue-gray-500;
  }

  .footer-bottom-menu-text {
    color: $white;
    font-size: 12px;
    text-decoration: none;
  }

  .footer-copyright {
    color: $blue-gray-200;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.05em;
    margin: 0;
  }
}

/* ------- HEADER ------- */
header {
  padding: 8px 24px;
  min-width: 1120px;
  border-bottom: 4px solid $blue-700;
  position: sticky;
  position: -webkit-sticky;
}

.header-inner {
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
}

.header-logo {
  padding: 8px 0px;
  width: 164px;
}

.header-right {
  align-items: last baseline;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
}

ul.header-menu-list {
  margin: 0;
}

ul.header-menu-list > li {
  margin-right: 32px;
  list-style: none;
}

ul.header-menu-list > li > a {
  display: inline-block;
  font-size: 16px;
  line-height: 23px;
  color: $gray-900;
  text-decoration: none;
}

ul.header-menu-list > li > a.header-mypage {
  font-weight: 700;
  color: $blue-700;
}

ul.header-menu-list > li img {
  display: inline-block;
  margin-right: 8px;
  width: 18px;
  height: 18px;
}

.header-btn {
  width: 148px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  border: 1px solid $blue-700;
  border-radius: 4px;
}

.header-login {
  margin-right: 8px;
  color: $blue-700;
  background-color: $white;
}

.header-signup {
  margin-right: 8px;
  color: $white;
  background-color: $blue-700;
}

a.header-signup:hover {
  color: $white;
}

.header-agent {
  color: $white;
  background-color: $blue-1000;
}

a.header-agent:hover {
  color: $white;
}

.header-btn img {
  width: 24px;
  height: 24px;
}

p.header-btn-txt {
  margin: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}

/*========================
  HOME
========================*/
h2.home-h2-left {
  margin-bottom: 40px;
  padding-bottom: 32px;
  font-size: 30px;
  line-height: 43px;
}

h2.home-h2-center {
  margin-bottom: 40px;
  padding-bottom: 32px;
  font-size: 28px;
  line-height: 41px;
}

/* ------- お役立ち情報 ------- */
ul.home-article-list {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-bottom: 40px;

  > li {
    width: calc(50% - 12px);

    > a {
      .article-main-wrap {
        margin-bottom: 12px;
        
        .thumbnail {
          width: 160px;
        }
  
        .title {
          font-size: 18px;
          line-height: 26px;
        }
      }

      .article-dtl-wrap {
        .category {
          font-size: 14px;
          line-height: 20px;
          border-radius: 50px;
        }

        .created-at {
          font-size: 16px;
          line-height: 23px;
        }
      }
    }
  }
}

/*========================
  FV
========================*/
.pc-firstview {
  position: relative;
  width: 100%;
  padding-top: 32.8%;
  background-image: url('../images/pc-firstview.webp');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;

  .home-search {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -46px;
    width: 1120px;
    margin: 0 auto;

    .home-search-dtl {
      margin-bottom: 8px;
      padding: 0 40px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $white;
    }

    .home-project-count {
      font-weight: 700;
      font-size: 22px;
      line-height: 27px;
    }

    .home-project-update-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }
}

.home-search-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1120px;
  padding: 16px 24px;
  border-radius: 100px;
  background-color: $white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);

  .home-search-item {
    display: block;
    padding: 0 16px;
    color: $gray-900;
    text-decoration: none;
    border-right: 1px solid $blue-gray-75;
  }

  .home-search-occupation {
    width: 256px;
  }

  .home-search-area {
    width: 240px;
  }

  .home-search-income {
    width: 200px;
  }

  .home-search-freeword {
    width: 256px;
    border-right: none;
  }

  .home-search-item:hover {
    opacity: 1;
  }

  .home-search-item-ttl {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .home-search-item-txt {
    position: relative;
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $gray-400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .home-search-item-txt.selected {
    color: $gray-900;
  }

  .home-search-item-txt:after,
  .home-search-income-select-wrap:after {
    position: absolute;
    top: 4px;
    right: 0;
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    background: url('../images/arrow_down.svg') no-repeat center / contain;
    pointer-events: none;
  }

  .home-search-income-select-wrap {
    position: relative;
  }

  .home-search-income-select {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $gray-400;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .home-search-income-select:hover {
    cursor: pointer;
  }

  .home-search-freeword-input {
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: $gray-900;
    border: none;
  }

  .home-search-freeword-input:focus,
  .home-search-income-select:focus {
    outline: none;
  }

  .home-search-freeword-input::placeholder {
    color: $gray-400;
  }

  .home-search-btn-wrap {
    width: 120px;
  }
}

.pc-firstview-bottom {
  padding: 93px 0 56px;
  .service-link {
    display: block;
    width: 600px;
    margin: 0 auto;

    > img {
      width: 100%;
    }
  }
}

/*========================
  SUPPORT
========================*/
.home-support-bg {
  padding: 72px 0;
  background-image: url('../images/home-support-pc.webp');
}

.home-support-card {
  width: 1120px;
}

.home-support-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .h3-home-support {
    font-size: 34px;
    line-height: 56px;
  }

  > img {
    display: block;
    width: 280px;
  }
}

ul.home-support-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin-bottom: 62px;

  > li {
    > img {
      display: block;
      width: 120px;
      margin: 0 auto 12px;
    }

    .home-support-icon-txt {
      display: block;
      margin: 0;
      font-weight: 700;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      color: $blue-gray-900;
    }
  }
}

.home-support-card-btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: 0 200px;
}

/*========================
  FLOW
========================*/
ul.home-flow-list {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  > li {
    width: 280px;

    img.flow-num {
      height: 30px;
      margin: 0 auto 16px;
    }

    img.flow-icon {
      width: 56px;
      height: 56px;
      margin: 0 auto 8px;
    }

    .home-flow-list-item-ttl {
      margin: 0 auto 16px;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: $blue-gray-900;
    }

    p.home-flow-list-item-txt {
      padding: 0px 16px;
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      color: $blue-gray-900;
    }
  }
}

/*========================
  QA
========================*/
ul.home-qa-list {
  width: 1000px;

  > li {
    margin-bottom: 24px;

    > a {
      .home-q {
        gap: 24px;

        img.q-icon {
          width: 24px;
        }

        h3.q-ttl {
          font-size: 20px;
          line-height: 29px;
        }

        img.q-arrow-down, img.q-arrow-up {
          width: 24px;
          height: 24px;
        }
      }

      .home-a {
        margin-top: 24px;
        padding: 12px 40px;
        font-size: 16px;
        line-height: 30px;
      }
    }
  }
}

/*========================
  SEARCH
========================*/
.search-result-summary .sr-num {
  font-size: 24px;
  line-height: 30px;
}

.project-side-search,
.project-side-search-no-border {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid $blue-gray-50;
}

.project-side-search-no-border {
  border: none;
}

.project-side-search-item-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.project-side-search-ttl-wrap {
  margin-bottom: 16px;
}

.project-side-search-ttl {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: $blue-700;
}

.project-side-search-modal-icon {
  width: 18px;
  height: 18px;
}

.form-select-income {
  font-size: 14px;
  line-height: 20px;

  .form-select-wrap {
    position: relative;
    display: inline-block;
    width: 92px;
  }

  .form-select-wrap::after {
    content: url('../images/arrow_down.svg');
    position: absolute;
    top: calc(50% - 9px);
    right: 8px;
    display: block;
    color: $blue-700;
  }
}

/*========================
  CARD SMALL
========================*/
.project-list-sm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  align-items: stretch;
  gap: 24px;
  margin-bottom: 40px;

  > li {
    width: calc(33.3% - 16px);
  }

  .project-card-sm {
    height: 100%;
    text-decoration: none;

    h3.project-card-ttl {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      color: $blue-700;
    }

    .project-card-occupation {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: $blue-gray-700;
    }

    ul.project-card-condition-box {
      > li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        color: $gray-900;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
      }

      > li:last-child {
        margin-bottom: 0;
      }
    }

    img.project-card-condition-icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .income-num {
      color: $red;
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
    }

    .income-unit {
      color: $gray-900;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;
    }
  }

  .project-card-sm:hover {
    opacity: .8;
  }
}

/*========================
  PAGE UNIQUE COMPONENTS
========================*/
.top-canvas {
  height: 1036px;
  background-image: url("../images/service_top.webp");
  margin: 0 auto;
  padding-bottom: 40px;
  text-align: center;

  .top-img-sp {
    display: none;
  }

  h1.service-title-layout {
    padding: 90px 0 32px; 
  }

  h1.service-title {
    font-size: 44px;
    font-weight: 600;
    line-height: 82px;
    color: $blue-700;
  }

  p.service-top-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 36px;
  }

  p.service-top-text > span.emphasis {
    font-weight: 600;
    background-color: $yellow-500;
  }
}

.parallelogram-blue {
  position: relative;
  width: 270px;
  height: 45px;
  text-align: center;
  padding: 8px 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: $white;
  z-index: 1;
}

.parallelogram-blue::before {
  transform: skewX(-15deg);
  content: '';/*ボックス作成*/
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin:0 20px;
  background: $blue-700;
  z-index: -1;
}

.customer-review {
  margin: 0 auto;
  text-align: left;

  .upper-content {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    > .upper-ttl {
      margin: auto 0;
    }
  }

  .review-body {
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    color: $blue-gray-900;
  }

  .lower-content {
    padding-top: 24px;
  }
}

.feature-icon {
  width: 120px;
  height: 120px;
}

/*========================
  PAGE UNIQUE CONTENT/SECTION
========================*/
.service-content {
  width: 1000px;
  margin: 0 auto;
  text-align: center;

  ul.reviews {
    padding: 48px 0;

    >li:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .review-read-more {
    display: flex;
    justify-content: flex-end;
    text-align: left;
  }

  .review-read-more > img {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }

  .text-closing {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 46px;
  }

  .onboarding-btns {
    display: flex;
    justify-content: center;
    margin-top: 40px auto;
  }

  .onboarding-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
  }

}

.service-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
}

/*========================
  PAGENATION
========================*/
nav.pagination {
  margin: 0 auto;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 51px;
    height: 63px;
    border-radius: 8px;
    border: 1px solid #C4D0D7;
    background-color: $white;
  }

  > span:hover {
    opacity: .6;
  }

  > span.gap:hover {
    opacity: 1;
  }

  > span:not(:first-child) {
    margin-left: 12px;
  }
  
  > span.current {
    background-color: $blue-700;
    color: $white;
  }

  > span a {
    display: block;
    width: 51px;
    height: 63px;
    padding: 20px 8px;
    border-radius: 8px;
    text-align: center;
    color: $blue-gray-900;
    text-decoration: none;
  }

  > span.first,
  > span.last {
    display: none;
  }
}